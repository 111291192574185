import React, { useContext } from "react";
import { graphql } from "gatsby";
import { MDXRenderer } from "gatsby-plugin-mdx";
import DefaultLayout from "../components/layouts/DefaultLayout";
import { defineCustomElements as deckDeckGoHighlightElement } from "@deckdeckgo/highlight-code/dist/loader";
import { AuthContext } from "../contexts/AuthContext";
import SEO from "../components/layouts/SEO";
import Hero from "../components/StaticPage/Hero";
import { IPost, ISiteMetaData } from "../types";
import { GatsbyImage, getImage, StaticImage } from "gatsby-plugin-image";
import FeaturesBanner from "../components/UI/FeaturesBanner";
import { MDXProvider } from "@mdx-js/react";
import CopyToClipboard from "../components/CopyToClipboard";
import FeaturedPosts from "../components/FeaturedPosts";
import ReportsForm from "../components/ReportsForm";
import AuthorCards from "../components/AuthorCard";


deckDeckGoHighlightElement();

interface IPostDetailsProps {
  data: {
    site: {
      siteMetadata: ISiteMetaData;
    };
    post: {
      frontmatter: IPost;
      excerpt: string;
      body: string;
      tableOfContents: string[];
      description: string;
    };
    allPosts: any;
  };
  pageContext: {
    slug: string;
  };
}

const ReportDetails: React.FC<IPostDetailsProps> = ({ data, pageContext }) => {
  const { post, allPosts } = data;
  let { isAuthenticated } = useContext(AuthContext);

  if (!post) return null;

  const image = getImage(
    post.frontmatter.featured_image?.childImageSharp?.gatsbyImageData
  );

  const reportImage: any = getImage(
    post.frontmatter.reportImage?.childImageSharp?.gatsbyImageData
  );

  const description = post.frontmatter?.excerpt
    ? post.frontmatter?.excerpt
    : post.excerpt;

  return (
    <DefaultLayout>
      <SEO
        title={`${post.frontmatter?.title} | Ensemble`}
        url={pageContext?.slug ? `${data?.site?.siteMetadata?.siteUrl}/blog/${pageContext.slug}` : ''}
        desc={description}
        ogImage={`${data?.site?.siteMetadata?.siteUrl}${post.frontmatter?.featured_image?.childImageSharp?.fixed?.src || '/assets/img/dbt.png'}`}
        article
      />
      <Hero
        title={post.frontmatter?.title}
        backgroundColor={["#40b7de", "#1b78e0"]}
        className="lg:!pb-[60px]"
      />
      <div className="md:flex gap-4 justify-center items-start">

        <div className="max-w-[760px] flex-1 px-6 mt-12">
          <article className="mb-8 prose prose-lg max-w-none article-smaller-paragraphs">
            <GatsbyImage
              image={image as any}
              alt={post.frontmatter.title}
              sizes="(min-width: 1140px) 1140px, 100vw"
              objectFit="contain"
              className="rounded-2xl overflow-hidden report-image"
              loading="eager"
            />
            <style>
              {`
                .article-smaller-paragraphs p,
                .article-smaller-paragraphs li {
                  font-size: 1rem;
                }
                .gatsby-resp-image-wrapper {
                  margin: 1.5em 0;
                }
                .report-image {
                  margin: 1em 0;
                }
                .report-image img {
                  margin: 0 !important;
                }
              `}
            </style>
            <MDXProvider
              components={{
                pre: CopyToClipboard,
              }}
            >
              <MDXRenderer localImages={post.frontmatter?.embeddedImagesLocal}>
                {post.body}
              </MDXRenderer>
            </MDXProvider>
          </article>
          <aside className="pb-14 lg:pb-[129px] hidden md:block">
            {post.frontmatter?.featured_posts && 
              <FeaturedPosts featuredPosts={post.frontmatter.featured_posts} allPosts={allPosts} />
            }
           
          </aside>
         
        </div>


      

        <div className="flex-1 bg-[#302536] max-w-[500px] w-[90%] md:mx-0 mx-auto mb-12 py-7 px-5 mt-12 rounded-2xl">
            {reportImage &&
              <div className="flex justify-center">
                <GatsbyImage
                  image={reportImage as any}
                  alt={post.frontmatter.title}
                  sizes="300px"
                  className="max-w-[300px] mx-auto block mt-1"
                />
              </div>
            }
            {(post.frontmatter?.authorName1 || post.frontmatter?.authorName2) &&
              <AuthorCards frontmatter={post.frontmatter} report />
            }
            {(reportImage || post.frontmatter?.authorName1) &&
              <div className="h-[1px] bg-[#524858] w-full mt-10 mb-10" />
            }
            <h2 className="mb-5 mt-2 lg:text-[28px] text-[24px] font-semibold text-white text-center">
              {post?.frontmatter?.ctatext ? post.frontmatter.ctatext : 'Get the Report'}
            </h2>
            {post?.frontmatter?.ctaParagraph && post?.frontmatter?.ctaParagraph !== "undefined" &&
              <p className="text-center mb-6 mt-[-1rem]">{post?.frontmatter?.ctaParagraph}</p>
            }
            <ReportsForm file={post.frontmatter.file} />
        </div>

        <aside className="pb-14 lg:pb-[129px] md:hidden w-[90%] mx-auto">
          {post.frontmatter?.featured_posts && 
            <FeaturedPosts featuredPosts={post.frontmatter.featured_posts} allPosts={allPosts} />
          }
        </aside>

      </div>
    </DefaultLayout>
  );
};

export const query = graphql`
  query ReportQuery($slug: String) {
    site {
      siteMetadata {
        title
        description
        siteUrl
      }
    }

    post: mdx(slug: { eq: $slug }) {
      body
      frontmatter {
        date_updated
        title
        shorttitle
        background_color
        slug
        file
        featured_posts
        excerpt
        ctatext
        ctaParagraph
        featured_image {
          childImageSharp {
            gatsbyImageData(placeholder: NONE)
            fixed(width: 1200, height: 630) {
              ...GatsbyImageSharpFixed
            }
          }
        }
        embeddedImagesLocal {
          childImageSharp {
            gatsbyImageData
          }
        }
        reportImage {
          childImageSharp {
            gatsbyImageData(placeholder: NONE)
          }
        }
        authorName1
        authorTitle1
        authorImage1
        authorLinkedIn1
        authorName2
        authorTitle2
        authorImage2
        authorLinkedIn2
      }
    }

    allPosts: allMdx(
      filter: { fields: { source: { eq: "blog" } } }
      sort: { fields: frontmatter___date_updated, order: ASC }
    ) {
      nodes {
        frontmatter {
          title
        }
        slug
      }
    }
  }
`;

export default ReportDetails;
